@use 'sass:map';
@use 'theming';

@mixin _element_and_icons($type) {
  $class: ".text-#{$type}";
  #{$class},
  .mat-mdc-menu-item .mat-icon-no-color#{$class},
  .mat-mdc-menu-item .mat-mdc-menu-submenu-icon#{$class} {
    @content;
  }
}

@mixin color($config-or-theme, $iq) {
  $config: theming.get-color-config($config-or-theme);

  @include _element_and_icons('primary') {
    color: theming.get-color-from-palette(map.get($config, primary), text);
  }
  @include _element_and_icons('bg-primary') {
    background: theming.get-color-from-palette(map.get($config, primary), default);
    color: theming.get-color-from-palette(map.get($config, primary), default-contrast);
  }
  @include _element_and_icons('info') {
    color: theming.get-color-from-palette(map.get($config, accent), text);
  }
  @include _element_and_icons('muted') {
    color: theming.get-color-from-palette(map.get($config, accent), text);
  }
  @include _element_and_icons('danger') {
    color: theming.get-color-from-palette(map.get($config, warn), text);
  }

  @include _element_and_icons('success') {
    color: map.get(map.get($iq, success), 500);
  }
  @include _element_and_icons('warning') {
    color: map.get(map.get($iq, warning), 500);
  }

  a, a:hover, a:focus, a:visited {
    color: theming.get-color-from-palette(map.get($config, primary), text);
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  $color: theming.get-color-config($theme);

  @if $color != null {
    @include color($color, map.get($theme-or-color-config, iq));
  }
}