@use 'sass:map';
@use 'theming';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

    .mat-mdc-list-item,
    .mat-mdc-list-option {
      &.active{
        color: theming.get-color-from-palette($primary, text);
      }
      &.cdk-drag{
        background: theming.get-color-from-palette($background, card);
      }

      .mat-mdc-list-item-unscoped-content {
        display: flex;
      }
    }

  .mat-mdc-list-item,
  .mat-mdc-list-option {
    .mat-checkbox-layout{
      margin-bottom: 0;
    }
    &.cdk-drag-preview{
      background: theming.get-color-from-palette($background, card);
    }
  }

  .mat-mdc-list-option,
  .mat-mdc-nav-list .mat-mdc-list-item,
  .mat-mdc-action-list .mat-mdc-list-item {
    &, &:hover, &:focus{
      &.active {
        background: theming.get-color-from-palette($background, 'selected-button');
      }
    }
  }

  .mat-mdc-list-single-selected-option {
    &, &:hover, &:focus {
      &.active {
        background: theming.get-color-from-palette($background, selected-button, 0.12);
      }
    }
  }

  .mat-mdc-list-drag-placeholder {
    @if map.get($config, is-dark) {
      background: theming.get-color-from-palette($primary, darker);
    } @else {
      background: theming.get-color-from-palette($primary, lighter);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  $color: theming.get-color-config($theme);

  @if $color != null {
    @include color($color);
  }
}