@use '@angular/material' as mat;

@use './variables';
@use './material-theme/body' as myBody;
@use './material-theme/mat-list' as myMatList;
@use './material-theme/sidebar' as mySidebar;
@use './material-theme/utils' as myUtils;
@use './material-theme/components/reporting-field' as myComponentReportingField;

@import 'https://fonts.googleapis.com/css?family=Material+Icons';

$fontConfig: (
        display-4: mat.define-typography-level(112px, 112px, 300, $dc-font-family, -0.0134em),
        display-3: mat.define-typography-level(56px, 56px, 300, $dc-font-family, -0.0089em),
        display-2: mat.define-typography-level(45px, 48px, 300, $dc-font-family, 0.0000em),
        display-1: mat.define-typography-level(34px, 40px, 300, $dc-font-family, 0.0074em),
        headline: mat.define-typography-level(24px, 32px, 300, $dc-font-family, 0.0000em),
        title: mat.define-typography-level(20px, 32px, 400, $dc-font-family, 0.0075em),
        subheading-2: mat.define-typography-level(16px, 28px, 300, $dc-font-family, 0.0094em),
        subheading-1: mat.define-typography-level(14px, 24px, 400, $dc-font-family),
        body-2: mat.define-typography-level(14px, 20px, 400, $dc-font-family, 0.0179em),
        body-1: mat.define-typography-level(14px, 20px, 300, $dc-font-family, 0.0179em),
        button: mat.define-typography-level(14px, 14px, 100, $dc-font-family, 0.0893em),
        caption: mat.define-typography-level(12px, 20px, 300, $dc-font-family, 0.0333em),
        input: mat.define-typography-level(inherit, 1.125, 300, $dc-font-family, 1.5px)
);

@include mat.all-component-typographies($fontConfig);
@include mat.core();

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);

$brand-palette: (
        100: #cbd7d9,
        200: #507b7f, // For slide toggle,
        500: #507b7f,
        700: #296361,
        contrast: (
                100: $dark-primary-text,
                500: $light-primary-text,
                700: $light-primary-text,
        )
);
$brand-accent-palette: (
        100: #d7d7d7,
        200: #636363, // For slide toggle,
        500: #636363,
        700: #494949,
        contrast: (
                100: $dark-accent-text,
                500: $light-accent-text,
                700: $light-accent-text,
        )
);

$my-primary: mat.define-palette($brand-palette);
$my-accent: mat.define-palette($brand-accent-palette);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: map-merge(
                mat.define-light-theme((
                        color: (
                                primary: $my-primary,
                                accent: $my-accent,
                                warn: $my-warn,
                        ),
                        density: -3
                )),
                (
                        iq: (
                                warning: mat.$amber-palette,
                                success: mat.$light-green-palette,
                        )
                )
);

@include mat.all-component-themes($my-theme);
@include mat.private-form-field-density(-5);
@include myBody.theme($my-theme);
@include myMatList.theme($my-theme);
@include mySidebar.theme($my-theme);
@include myUtils.theme($my-theme);
@include myComponentReportingField.theme($my-theme);

@mixin theme-color($dark, $my-primary, $my-accent, $my-warn, $iq-warning, $iq-success) {
  $my-color-theme: map-merge(
                  mat.define-light-theme((
                          color: (
                                  primary: $my-primary,
                                  accent: $my-accent,
                                  warn: $my-warn,
                          ),
                  )),
                  (
                          iq: (
                                  warning: $iq-warning,
                                  success: $iq-success,
                          )
                  )
  );

  @if ($dark) {
    $my-color-theme: map-merge(
                    mat.define-dark-theme((
                            color: (
                                    primary: $my-primary,
                                    accent: $my-accent,
                                    warn: $my-warn,
                            ),
                    )),
                    (
                            iq: (
                                    warning: $iq-warning,
                                    success: $iq-success,
                            )
                    )
    );
  }

  @include mat.all-component-colors($my-color-theme);
  @include myBody.theme($my-color-theme);
  @include myMatList.theme($my-color-theme);
  @include mySidebar.theme($my-color-theme);
  @include myUtils.theme($my-color-theme);
  @include myComponentReportingField.theme($my-color-theme);
}

.theme-alternate {
  @include theme-color(
                  true,
                  $my-primary,
                  $my-accent,
                  $my-warn,
                  mat.$amber-palette,
                  mat.$light-green-palette,
  );
}
.theme-debug{
  @include theme-color(
                  false,
                  mat.define-palette((
                          100: lighten(#ff00ff, 0.9),
                          200: lighten(#ff00ff, 0.5), // For slide toggle,
                          500: lighten(#ff00ff, 0.5),
                          700: #ff00ff,
                          contrast: (
                                  100: $dark-primary-text,
                                  500: $light-primary-text,
                                  700: $light-primary-text,
                          )
                  )),
                  mat.define-palette((
                          100: lighten(#00ff00, 0.9),
                          200: lighten(#00ff00, 0.5), // For slide toggle,
                          500: lighten(#00ff00, 0.5),
                          700: #00ff00,
                          contrast: (
                                  100: $dark-primary-text,
                                  500: $light-primary-text,
                                  700: $light-primary-text,
                          )
                  )),
                  $my-warn,
                  mat.$amber-palette,
                  mat.$light-green-palette,
  );
}
.theme-debug.theme-alternate {
  @include theme-color(
                  true,
                  mat.define-palette((
                          100: lighten(#ff00ff, 0.9),
                          200: lighten(#ff00ff, 0.5), // For slide toggle,
                          500: lighten(#ff00ff, 0.5),
                          700: #ff00ff,
                          contrast: (
                                  100: $dark-primary-text,
                                  500: $light-primary-text,
                                  700: $light-primary-text,
                          )
                  )),
                  mat.define-palette((
                          100: lighten(#00ff00, 0.9),
                          200: lighten(#00ff00, 0.5), // For slide toggle,
                          500: lighten(#00ff00, 0.5),
                          700: #00ff00,
                          contrast: (
                                  100: $dark-primary-text,
                                  500: $light-primary-text,
                                  700: $light-primary-text,
                          )
                  )),
                  $my-warn,
                  mat.$amber-palette,
                  mat.$light-green-palette,
  );
}
