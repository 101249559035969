$dc-primary-color: 				#507b7f;
$dc-primary-color-dark: 		#296361;
$dc-primary-color-light:        #eef3f3;
$dc-primary-color-white:        #cad7d8;
$dc-secondary-color: 			#636363;
$dc-secondary-color-dark: 		#494949;
$dc-secondary-color-white:      #a2a6a8;

$dc-color-info: #0095ff;
$dc-color-success: #0095ff;
$dc-color-warning: #ffaa00;
$dc-color-danger: #ff3d71;

$brand-primary:              $dc-primary-color;
$brand-primary-dark:         $dc-primary-color-dark;

$dc-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;

$dc-breakpoint-sm: 576px;
$dc-breakpoint-md: 768px;
$dc-breakpoint-lg: 992px;
$dc-breakpoint-xl: 1200px;
$dc-mobile-breakpoint-name: 'md';