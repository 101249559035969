@use 'sass:map';
@use 'theming';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  body {
    color: theming.get-color-from-palette(map.get($config, foreground), text) !important;
    background: theming.get-color-from-palette(map.get($config, background), app-bar) !important;
  }

  hr {
    border-top: 1px solid theming.get-color-from-palette(map.get($config, foreground), text, 0.1);
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  $color: theming.get-color-config($theme);

  @if $color != null {
    @include color($color);
  }
}