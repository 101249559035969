//@import "node_modules/bootstrap/scss/forms";
//@import "node_modules/bootstrap/scss/input-group";

.input-group {
  @include make-row();
  align-items: stretch;
  width: 100%;

  > label {
    text-align: right;
    font-weight: 600;
  }
}