@import "variables";

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  width: 0.4rem;
  box-shadow: inset 0 0 0.3rem grey;
}

::-webkit-scrollbar-thumb {
  background-color: $brand-primary;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $brand-primary-dark;
}

/*
{
  &::-webkit-scrollbar-track {
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover{
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.3rem grey;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $brand-primary;
      &:hover {
        background-color: $brand-primary-dark;
      }
    }
  }
}
*/