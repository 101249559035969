@import "variables";

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
body {
  font-family: $dc-font-family;
  font-weight: 300;
}

p {
  margin: 1rem 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

[hidden] {
  display: none !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a, a:hover, a:focus {
  text-decoration: none;
}

pre {
  overflow: auto;
}

.m-0 {
  margin: 0 !important
}

.mt-0, .my-0 {
  margin-top: 0 !important
}

.mb-0, .my-0 {
  margin-bottom: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.mt-1, .my-1 {
  margin-top: .25rem !important
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.mt-2, .my-2 {
  margin-top: .5rem !important
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.mt-3, .my-3 {
  margin-top: 1rem !important
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.mt-5, .my-5 {
  margin-top: 3rem !important
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important
}

@for $i from 1 through 100 {
  .w-#{$i}{
    width: #{$i}#{'%'};
  }
}