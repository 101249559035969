@import "node_modules/bootstrap/scss/grid";

@import "../mixins/_mobile";

.only-mobile {
  @include desktop() {
    display: none !important;
  }
}

.only-desktop {
  @include mobile() {
    display: none !important;
  }
}