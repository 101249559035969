@import "variables";

.mdc-button {
  height: auto !important;
  min-height: 36px; //TODO density
  overflow: hidden;

  .mdc-button__label {
    text-transform: uppercase;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    > span {
      vertical-align: middle;
    }
  }
}

.cdk-overlay-pane.app-card-dialog-panel {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  > * {
    pointer-events: initial;
  }

  &.app-card-dialog-panel--top {
    bottom: 0;
    justify-content: flex-start;
  }

  &.app-card-dialog-panel--bottom {
    top: 0;
    justify-content: flex-end;
  }

  &.app-card-dialog-panel--left {
    right: 0;
    align-items: flex-start;
  }

  &.app-card-dialog-panel--right {
    left: 0;
    align-items: flex-end;
  }

  &.app-card-with-icon {
    padding: 1.5rem 0 0;
    max-height: calc(100vh - 3rem);
    border-radius: 5px;

    .mat-mdc-dialog-surface {
      box-shadow: none;
      background: none;
      overflow: visible;

      app-card {
        width: 100%;
        //box-shadow: var(--mdc-dialog-container-elevation, 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12));
      }
    }
  }
}

.mat-mdc-card {
  overflow: auto;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-table {
  .mdc-data-table__cell {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

// generated typography classes from https://v16.material.angular.io/guide/typography#:~:text=The%20table%20below%20lists%20the%20CSS%20classes%20emitted%20and%20the%20native%20elements%20styled
// made more specific so they may be used to override styles
body {
  .mat-headline-1	{
    @extend .mat-headline-1
  }
  .mat-headline-2	{
    @extend .mat-headline-2
  }
  .mat-headline-3	{
    @extend .mat-headline-3
  }
  .mat-headline-4	{
    @extend .mat-headline-4
  }
  .mat-h1, .mat-headline-5 {
    @extend .mat-h1
  }
  .mat-h2, .mat-headline-6 {
    @extend .mat-h2
  }
  .mat-h3, .mat-subtitle-1 {
    @extend .mat-h3
  }
  .mat-h4, .mat-body-1 {
    @extend .mat-h4
  }
  .mat-h5 {
    @extend .mat-h5
  }
  .mat-h6	{
    @extend .mat-h6
  }
  .mat-body, .mat-body-2 {
    @extend .mat-body
  }
  .mat-body-strong, .mat-subtitle-2 {
    @extend .mat-body-strong
  }
  .mat-small, .mat-caption {
    @extend .mat-small
  }
}
